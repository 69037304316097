// @media screen and (max-width: 992px) {
//     .main-container {
//         left: 0px;
//     }
// }
// @media screen and (min-width: 992px) {
//     .push-right {
//       .main-container {
//           left: 0px;
//       }
//     }
// }

// @media screen and (max-width: 992px) {
//     .push-right {
//         #sidebar {
//             left: 0px !important;
//         }
//     }
// }
#sidebar {
    // left: 0px !important;
    display: none !important;
}

.push-right {
    #sidebar {
        // left: 0px !important;
        display: block !important;
        ;
    }
}

@media screen and (max-width: 540px) {
    #sidebar {
        // left: 0px !important;
        display: none !important;
    }

    .push-right {
        #sidebar {
            // left: 0px !important;
            display: block !important;
            ;
        }
    }
}