@import url('https://fonts.googleapis.com/css2?family=Mada:wght@200;300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mallanna&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manjari&display=swap');

@font-face {
    font-family: 'robotoLight';
    src: url('../assets/fonts/FontinSans/roboto.light.ttf') format('opentype');
}

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/material/theming';
@import "@ng-select/ng-select/themes/material.theme.css";
// $font-family: 'Fontil Sans, monospace',
$custom-typography: mat-typography-config($font-family: 'Mada',
        $headline: mat-typography-level(32px, 48px, 700),
        $body-1: mat-typography-level(16px, 24px));
@include angular-material-typography($custom-typography);

.mat-toolbar.mat-primary {
    background: rgb(224 224 224);
    // $primary;
    color: $primary-btn;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}