@import '../mixin';
$default-text: 14px;
$sm-text: 12px;
$lg-text: 16px;
$body-bg: #fff;
$sidebar-bg: #465294;
$sidebar-fonts-color: #fff;
$card-bg: #fff;
$card-border: #e4e3e3;
$white: #fff;
$darkgrey: #a7a6a6;
$blue: #03a9f4;
$black: #000;
$select-color: rgba($black, .1);
$border-color: rgba($black, .1);
$table-border-color: #e6e6e6;
$fonts-color: #203851;
// $primary: #153e6b;
$primary: #203851;
$primary-second: #203851;
$primary-third: #03a9f4;
$primary-fourth: #00bcd4;
$secondory: #ffffff;
// $secondory-text: #A7AEB1;
$secondory-text: #757575;
$success: #16be9a;
$danger: #f35800;
$warning: #ff7403;
$info: #4f5b67;
$title: #4f5b67;
$primary-btn: #203851;
$secondory-btn: #203851;
$primary-mybackpathways: #203851;
$popup: #f1f1f1;
$popup-header: #0e2844;

body {
    background: $body-bg;
    overflow-y: hidden !important;
}

.bg-default {
    @include linear-gradient(to top right, darken($card-bg, 40%), darken($card-bg, 30%));
}

.bg-primary {
    @include linear-gradient(to top right, $primary, #ffd17c);
}

.bg-success {
    @include linear-gradient(to top right, $success, #42e697);
}

.bg-danger {
    @include linear-gradient(to top right, $danger, #fda09c);
}

.bg-warning {
    @include linear-gradient(to top right, $warning, #fac980);
}

.bg-info {
    @include linear-gradient(to top right, $info, #15f8bf);
}

button.mat-default {
    @include linear-gradient(to top right, darken($card-bg, 40%), darken($card-bg, 30%));
}

button.mat-primary {
    @include linear-gradient(to top right, $primary-btn, $secondory-btn);
}

button.mat-green {
    @include linear-gradient(to top right, #48af2c, #48af2c);
}

button.mat-red {
    @include linear-gradient(to top right, #ff0000, #ff0000);
}

button.mat-gray {
    @include linear-gradient(to top right, #ffffff, #ffffff);
}

button.mat-success {
    @include linear-gradient(to top right, $success, #42e697);
}

button.mat-danger {
    @include linear-gradient(to top right, $primary-btn, $secondory-btn);
    color: white;
}

button.mat-warning {
    @include linear-gradient(to top right, $warning, #fca063);
}

button.mat-info {
    @include linear-gradient(to top right, $info, #15f8bf);
}

button.green-text:hover {
    color: #48af2c;
}

button.red-text:hover {
    color: #ff0000;
}

// button.green-text[disabled] {
//     color: #00000061;
// }

// button.red-text[disabled] {
//     color: #00000061;
// }

//custom
.mdc-fab {
    background-color: $primary !important;
}

::ng-deep .mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background,
.mdc-checkbox__ripple {
    background-color: $primary !important;
    border-color: $primary !important;
}

::ng-deep .mdc-switch__ripple {
    display: none;
}

::ng-deep .mat-ripple-element {
    display: none;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #203851 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
    height: 10px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #203851;
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.timepicker-overlay {
    z-index: 9999 !important;

    .timepicker__header,
    .clock-face__number>span.active,
    .clock-face__clock-hand {
        background-color: $primary !important;
    }

    .timepicker-button {
        color: $primary !important;
    }
}

// .material-symbols-outlined {
//     font-family: 'Material Symbols Outlined';
//     font-variation-settings:
//         'FILL' 0,
//         'wght' 400,
//         'GRAD' 0,
//         'opsz' 24;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 24px;
//     display: inline-block;
//     line-height: 1;
//     -webkit-font-feature-settings: 'liga';
//     -webkit-font-smoothing: antialiased;
// }

.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.material-symbols-filled {
    font-family: 'Material Symbols Outlined';
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;
}

.filled {
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;
}